import { List, ListItem, ListItemAvatar, ListItemText, Typography } from '@material-ui/core';
import { mdiCalculatorVariant, mdiClipboardText, mdiFinance, mdiHomeAnalytics } from '@mdi/js';
import Icon from '@mdi/react';
import { navigate } from 'gatsby';
import React, { useState } from 'react';
import Header from '../../components/header';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import UpdatedInfo from '../../components/templates/UpdatedInfo';
import { useGlobalStyles } from '../../utils/styles';
import { sevacDocuments, sevacFuente, sevacUpdatedAt } from '../../utils/transparencia-difusion/constants';
import { IListDocs } from '../../utils/transparencia-difusion/interfaces';
import { useStyles } from '../../utils/transparencia-difusion/styles';

const Sevac = () => {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const [fecha, setFecha] = useState<string>(sevacUpdatedAt);
  const [fuente, setFuente] = useState<string>(sevacFuente);
  const [data, setData] = useState<IListDocs[]>(sevacDocuments);

  return (
    <Layout>
      <SEO title='Información Financiera' />

      <div className={globalClasses.container}>
        <Header title='Información Financiera' />

        <div className={globalClasses.content}>
          <Typography>Apartados de Información Financiera del Instituto de Seguridad Social del Estado de Tabasco:</Typography>

          <List className={classes.list}>                                         
                  <ListItem  button onClick={() => { 
                    navigate("/transparencia-difusion/sevac")
                   }}>
                    <ListItemAvatar>                 
                    <Icon path={mdiFinance} size={1.7} color='#000' />                     
                    </ListItemAvatar>
                    <ListItemText primary=" Armonización Contable" />
                  </ListItem>

                  <ListItem  button onClick={() => { 
                    navigate("/transparencia-difusion/bienes")
                   }}>
                    <ListItemAvatar>                                            
                      <Icon path={mdiHomeAnalytics} size={1.7} color='#000' />                                       
                    </ListItemAvatar>
                    <ListItemText primary=" Padrón de Bienes" />
                  </ListItem>
                
                  <ListItem  button onClick={() => { 
                    navigate("/transparencia-difusion/pasivo-historico")
                   }}>
                    <ListItemAvatar>                                            
                      <Icon path={mdiClipboardText} size={1.7} color='#000' />                                       
                    </ListItemAvatar>
                    <ListItemText primary=" Pasivo histórico de los entes públicos" />
                  </ListItem>

                  
                  <ListItem  button onClick={() => { 
                    navigate("/transparencia-difusion/cuenta-publica")
                   }}>
                    <ListItemAvatar>                                            
                      <Icon path={mdiCalculatorVariant} size={1.7} color='#000' />                                       
                    </ListItemAvatar>
                    <ListItemText primary=" Cuenta pública" />
                  </ListItem>
         
          </List>

          <UpdatedInfo fuente={fuente} updatedAt={fecha} />
        </div>
      </div>
    </Layout>
  );
};

export default Sevac;